import "../styles/global.css";
import "../styles/global-sass.scss";
import "../styles/variables.scss";
import "../utils/scripts/wdyr";
import "react-perfect-scrollbar/dist/css/styles.css";

import { IncomingMessage } from "http";
import App, { AppContext } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useStore } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTradeData } from "@/contexts/../hooks/trade";
import { useDeviceInfo } from "@/utils/hooks/Device";
import { appWithTranslation } from "next-i18next";
import { getLocationParam } from "@/utils/url";
import { getBrandName } from "@/utils/helpers";
import { isServerSide } from "../utils";

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {},
    mode: "dark",
});

import { wrapper } from "../redux/store";
import { TradeChartGlobalAPIContext, useTradeChartGlobalAPI } from "@/contexts/ChartAPI";
import { useTradeChartLoader } from "@/hooks/chart/loader";

//const defaultUrlQuery = { api_url: undefined };

const getInitThemeMode = () => {
    if (isServerSide()) {
        return "dark";
    }

    const brandsWithDefaultLightTheme = ["investico", "invesacapital", "365trader"];
    const brand = getBrandName();

    if (brandsWithDefaultLightTheme.includes(brand.toLocaleLowerCase())) {
        return "light";
    }

    return getLocationParam("theme") === "light" ? "light" : "dark";
};

const MyApp = ({ Component, pageProps }) => {
    const router = useRouter();
    //const { query = defaultUrlQuery, userAgent, meta } = pageProps;
    const store = useStore(state => state);

    const [mode, setMode] = React.useState<"light" | "dark">(getInitThemeMode());

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                const nextMode = mode === "dark" ? "light" : "dark";
                const htmlEl = document.documentElement;

                htmlEl.classList.add(`theme-${nextMode}`);
                htmlEl.classList.remove(`theme-${mode}`);

                setMode(nextMode);

                // trigger resize event to correctly fill the free space with the chart
                window.dispatchEvent(new Event("resize"));
            },
            mode,
        }),
        [mode]
    );

    useEffect(() => {
        document.documentElement.classList.add(`theme-${mode}`);
    }, []);

    useEffect(() => {
        const langFromQuery = getLocationParam("lang");

        if (langFromQuery) {
            router.replace(router.asPath, router.asPath, { locale: langFromQuery });
        }
    }, []);

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                    primary: {
                        main: mode === "light" ? "#FFFFFF" : "#1C1F25",
                    },
                    secondary: {
                        main: mode === "light" ? "#FFFFFF" : "#444854",
                    },
                    background: {
                        default: mode === "light" ? "#FFFFFF" : "#1C1F25",
                        paper: mode === "light" ? "#FFFFFF" : "#1C1F25",
                    },
                },
            }),
        [mode]
    );

    useTradeChartLoader();
    useTradeData();
    useDeviceInfo();

    const chartGlobalAPI = useTradeChartGlobalAPI();

    const preCacheImages = [
        "/sound/ok.mp3",
        "/images/warning.svg",
        "/images/warning.svg",
        "/images/warning_light.svg",
        "/images/success.svg",
        "/images/success_white.svg",
        "/images/SuccessFeedback.svg",
        "/images/market.svg",
        "/images/market-closed_dark.svg",
        "/images/market-closed_light.svg",
        "/images/market_black.svg",
        "/images/marketClosed.svg",
        "/images/marketClosedBlack.svg",
    ];

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <PersistGate persistor={store.__persistor}>
                    <Head>
                        {preCacheImages &&
                            preCacheImages.map(image => (
                                <link key={`key_${image}`} rel="preload" href={image} as="image" />
                            ))}
                        <link rel="preconnect" href="https://fonts.googleapis.com" />
                        <link rel="preconnect" href="https://fonts.gstatic.com" />
                        <link
                            href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
                            rel="stylesheet"
                        />
                        <title>Trading Platform</title>
                    </Head>
                    <TradeChartGlobalAPIContext.Provider value={chartGlobalAPI}>
                        <Component {...pageProps} />
                    </TradeChartGlobalAPIContext.Provider>
                </PersistGate>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

const getUserAgent = (req: IncomingMessage): string => {
    return req ? req?.headers["user-agent"] : navigator?.userAgent;
};

const getQueryParams = params => {
    return Object.keys(params).reduce((acc, k) => {
        if (k !== "lang") {
            const query = `${k}=${params[k]}`;
            acc = `${acc}&${query}`;
            if (acc[0] === "&") {
                acc.replace("&", "");
            }
        }

        return acc;
    }, "");
};

MyApp.getInitialProps = async (appContext: AppContext) => {
    const { Component, ctx, router } = appContext;
    const { query, route } = router;
    if (route.includes("error")) {
        if (ctx.res) {
            ctx.res.writeHead(301, {
                Location: `/?${getQueryParams(query)}`,
            });
            ctx.res.end();
        }
    }
    const appProps = await App.getInitialProps(appContext);

    const pageProps = {
        query,
        userAgent: getUserAgent(ctx.req),
        ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
    };
    return { ...appProps, pageProps };
};

// @ts-ignore
export default wrapper.withRedux(appWithTranslation(MyApp));
