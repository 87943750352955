import { useEffect, useRef, useCallback, useState, useContext, RefObject } from "react";

import { useWindowResize } from "@/utils/hooks/Device";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";

import { deleteHLine, drawHLine, getSymbolPrecision, runChartInstance } from "./helpers";
import { useUI } from "@/redux/selectors/uiSelector";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { TradeChartGlobalAPIContext } from "@/contexts/ChartAPI";
import { ColorModeContext } from "../../pages/_app";
import { IChartInstanceObject } from "@/hooks/chart/interfaces";
import { useMarketSchedule } from "../trade/schedule";
import { ISymbolInfo } from "@/services/trade/symbols";
import { useDispatch } from "react-redux";
import { DevicePlatform } from "@/redux/interfaces/IDevice";

interface IProps {
    wrapRef?: RefObject<HTMLDivElement>;
    showLoader?: () => void;
    hideLoader?: () => void;
    chartType?: TradeChartType;
    symbolInfo?: ISymbolInfo;
    isChartLoaded?: (value: boolean) => void;
}

export enum TradeChartType {
    Desktop,
    Mobile,
    EditOrder,
    PendingOrder,
}

export enum TradeChartTimeframe {
    Min1 = "1m",
    Min5 = "5m",
    Min15 = "15m",
    Min30 = "30m",
    Hour = "1h",
    Hour4 = "4h",
    Day = "1d",
    Week = "1w",
    Month = "1MON",
}

export enum TradeChartPriceType {
    Candlesticks = "candlesticks",
    HollowCandlesticks = "hollowcandlesticks",
    Heikinashi = "heikinashi",
    HLC = "hlc",
    OHLC = "ohlc",
    Line = "line",
    Area = "area",
    Dots = "dots",
    Histogram = "histogram",
}

export interface ITradeChartAPI {
    changeTimeframe: (t: TradeChartTimeframe) => void;
    changePriceType: (p: TradeChartPriceType) => void;
    addStopLossLine: (price: number) => void;
    removeStopLossLine: () => void;
    addTakeProfitLine: (price: number) => void;
    removeTakeProfitLine: () => void;
}

export const initPriceChartType = TradeChartPriceType.Candlesticks;
export const initTradeChartTimeframe = TradeChartTimeframe.Min5;

const useTradeChart = (props: IProps = {}): ITradeChartAPI => {
    const {
        wrapRef = null,
        chartType = TradeChartType.Desktop,
        showLoader = () => {},
        hideLoader = () => {},
        symbolInfo: renderedSymbolInfo = null,
        isChartLoaded = () => {},
    } = props;

    const chartInstanceRef = useRef<IChartInstanceObject | null>(null);
    const stopLossLineRef = useRef<string>(null);
    const takeProfitLineRef = useRef<string>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { platform: devicePlatform } = useDevice();
    const isDesktop = devicePlatform === "Desktop";
    const { isChartReady, isFooterExpanded, isAssetsTableExpanded, isTradeOrderViewOpen } = useUI();
    const { updateGlobalChartAPI } = useContext(TradeChartGlobalAPIContext);
    const colorMode = useContext(ColorModeContext);

    const dispatch = useDispatch();

    // effects & state
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const symbolInfo = renderedSymbolInfo || symbolsInfoById[activeSymbolId];
    const { isMarketOpen = false } = useMarketSchedule(symbolInfo?.id);

    useEffect(() => {
        isChartLoaded(isLoaded);
    }, [isLoaded]);

    useEffect(() => {
        if (!isChartReady || (chartType === TradeChartType.EditOrder && !renderedSymbolInfo)) {
            return;
        }

        runChartInstance(
            activeSymbolId,
            dispatch,
            chartInstanceRef,
            isDesktop,
            symbolInfo,
            colorMode?.mode || "dark",
            showLoader,
            hideLoader,
            () => setIsLoaded(true),
            chartType,
            isMarketOpen
        );

        return () => {
            if (chartInstanceRef?.current?.chart?.CleanUp) {
                chartInstanceRef.current.chart.CleanUp(chartInstanceRef.current);
            }
        };
    }, [activeSymbolId, isChartReady, colorMode.mode]); // NOTE: it's critical to setup the script only once

    // symbol change
    useEffect(() => {
        if (
            !chartInstanceRef.current?.chart?.ChangeSymbol ||
            chartType === TradeChartType.EditOrder ||
            chartType === TradeChartType.PendingOrder
        ) {
            return;
        }

        chartInstanceRef.current.chart.ChangeSymbol({
            id: activeSymbolId,
            name: symbolInfo?.ex?.displayName,
            precision: getSymbolPrecision(symbolInfo?.point),
        });
    }, [activeSymbolId, isLoaded, symbolInfo?.ex?.displayName, symbolInfo?.point]);

    const getMobileReservedHeight = () => {
        if (!isMarketOpen) {
            return 58 + 56 + 44 + 56 + 147;
        }

        if (chartType === TradeChartType.EditOrder || chartType === TradeChartType.PendingOrder) {
            return 346;
        }

        return 303;
    };

    const getTabletReservedHeight = () => {
        if (!isMarketOpen) {
            return 58 + 56 + 44 + 56 + 147;
        }
        return 324;
    };

    const updateChartSize = useCallback(() => {
        if (!chartInstanceRef.current || !chartInstanceRef.current.api) {
            return;
        }

        if (chartType === TradeChartType.EditOrder) {
            chartInstanceRef?.current?.api?.layout?.set("width||height", 600 + "||" + 150);

            return;
        }

        const doc = document.documentElement;
        const windowHeight = doc.clientHeight;
        const windowWidth = doc.clientWidth;

        if (windowWidth < 601 || devicePlatform === DevicePlatform.Mobile) {
            // mobile
            const reservedHeight = getMobileReservedHeight();
            const height = windowHeight - reservedHeight;

            chartInstanceRef?.current?.api?.layout?.set(
                "width||height",
                doc.clientWidth + "||" + height
            );

            return;
        }

        if ((windowWidth > 600 && windowWidth < 1024) || devicePlatform === DevicePlatform.Tablet) {
            // tablet
            const reservedHeight = getTabletReservedHeight();
            const height = windowHeight - reservedHeight;

            chartInstanceRef?.current?.api?.layout?.set(
                "width||height",
                doc.clientWidth + "||" + height
            );

            return;
        }

        // desktop
        if (!wrapRef?.current) {
            return;
        }

        const wrapWidth = wrapRef.current.clientWidth;
        const screenWidth = screen?.width || 1367;
        const heightOnSmallResolution = 397;
        const isSmallResolution = screenWidth < 1000;
        const commonHeight = windowHeight - 170 - (isFooterExpanded ? 295 : 0);

        const width = wrapWidth - 45;
        const height = isSmallResolution ? heightOnSmallResolution : commonHeight;

        chartInstanceRef?.current?.api?.layout.set("width||height", width + "||" + height);
    }, [wrapRef, isFooterExpanded, isAssetsTableExpanded, isTradeOrderViewOpen]);

    useEffect(() => {
        updateChartSize();
    }, [updateChartSize, wrapRef, activeSymbolId]);

    useWindowResize({
        onResize: updateChartSize,
    });

    useEffect(() => {
        if (isLoaded) {
            updateChartSize();
        }
    }, [isLoaded, updateChartSize, activeSymbolId]);

    const changePriceType = (priceType: TradeChartPriceType) => {
        if (!chartInstanceRef.current) {
            return;
        }

        chartInstanceRef.current.chart.ChangePriceType(priceType);
    };

    const changeTimeframe = (timeFrame: TradeChartTimeframe) => {
        if (!chartInstanceRef.current) {
            return;
        }

        chartInstanceRef.current.chart.ChangeTimeScale(timeFrame);
    };

    const addStopLossLine = price => {
        if (!chartInstanceRef.current) {
            return;
        }

        stopLossLineRef.current = drawHLine(
            price,
            "StopLoss",
            stopLossLineRef.current,
            chartInstanceRef.current,
            colorMode?.mode,
            chartType
        );
    };

    const addTakeProfitLine = price => {
        if (!chartInstanceRef.current) {
            return;
        }

        takeProfitLineRef.current = drawHLine(
            price,
            "TakeProfit",
            takeProfitLineRef.current,
            chartInstanceRef.current,
            colorMode?.mode,
            chartType
        );
    };

    const removeStopLossLine = () => {
        if (!chartInstanceRef.current || !stopLossLineRef.current) {
            return;
        }

        deleteHLine(stopLossLineRef.current, chartInstanceRef.current);
    };

    const removeTakeProfitLine = () => {
        if (!chartInstanceRef.current || !takeProfitLineRef.current) {
            return;
        }

        deleteHLine(takeProfitLineRef.current, chartInstanceRef.current);
    };

    useEffect(() => {
        if (isLoaded) {
            updateGlobalChartAPI({
                changePriceType,
                changeTimeframe,
                addStopLossLine,
                removeStopLossLine,
                addTakeProfitLine,
                removeTakeProfitLine,
            });
        }
    }, [isLoaded]);

    return {
        changePriceType,
        changeTimeframe,
        addStopLossLine,
        removeStopLossLine,
        addTakeProfitLine,
        removeTakeProfitLine,
    };
};

export default useTradeChart;
