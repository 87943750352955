import { ISetAnimationParams } from "@/hooks/common/useAnimation";
import {
    TradeTabs,
    ITradeOrderModalTypes,
    IUIState,
    TradeOrderModalParamTypes,
} from "../interfaces/IUI";

const initialState: IUIState = {
    isTradeOrderViewOpen: true,
    isAssetsTableExpanded: false,
    tradeOrderModal: null,
    tradeOrderModalParams: null,
    isAssetsTabActive: true,
    isTradesTabActive: false,
    isWalletTabActive: false,
    isFooterExpanded: false,
    isFooterExpandedFullScreen: false,
    isOpenTradesOpen: false,
    isPendingOrdersOpen: false,
    isTradingHistoryOpen: false,
    isOrderModifyPopupOpen: false,
    isOrderModifyPopupRequestPending: false,
    showMobileFooter: true,
    showMobileHeader: true,
    isSingleAssetViewActive: false,
    isCalculatorViewActive: false,
    alertMessageFavoriteAdded: "",
    alertMessageFavoriteRemoved: "",
    isMobileAccountInfoOpen: false,
    isMobilePendingOrdersOpen: false,
    isChartReady: false,
    isFirstRenderAnimationFinished: false,
    animationParams: { class: null, params: null },
    navTab: TradeTabs.chart,
    isExpandButtonLocked: false,
    isNotificationsPopupVisible: false,
    assetToScrollOn: null,
    isTradeAlertsLoading: false,
};

export enum UIActionTypes {
    SET_TRADE_ORDER_VIEW = "ui/SET_TRADE_ORDER_VIEW",
    SET_IS_CHART_READY = "ui/SET_IS_CHART_READY",
    SET_IS_FIRST_RENDER_ANIMATION_FINISHED = "ui/SET_IS_FIRST_RENDER_ANIMATION_FINISHED",
    SET_IS_ASSETS_TABLE_EXPANDED = "ui/SET_IS_ASSETS_TABLE_EXPANDED",
    OPEN_TRADE_ORDER_MODAL = "ui/OPEN_TRADE_ORDER_MODAL",
    CLOSE_TRADE_ORDER_MODAL = "ui/CLOSE_TRADE_ORDER_MODAL",
    MOBILE_SET_IS_ASSETS_TAB_ACTIVE = "ui/MOBILE_SET_IS_ASSETS_TAB_ACTIVE",
    MOBILE_SET_IS_TRADES_TAB_ACTIVE = "ui/MOBILE_SET_IS_TRADES_TAB_ACTIVE",
    MOBILE_SET_IS_WALLET_TAB_ACTIVE = "ui/MOBILE_SET_IS_WALLET_TAB_ACTIVE",
    MOBILE_SET_IS_SINGLE_ASSET_VIEW_ACTIVE = "ui/MOBILE_SET_IS_SINGLE_ASSET_VIEW_ACTIVE",
    TOGGLE_MOBILE_FOOTER = "ui/TOGGLE_MOBILE_FOOTER",
    TOGGLE_MOBILE_HEADER = "ui/TOGGLE_MOBILE_HEADER",
    //
    TOGGLE_EXPAND_FOOTER = "ui/TOGGLE_EXPAND_FOOTER",
    TOGGLE_EXPAND_FOOTER_FULL_SCREEN = "ui/TOGGLE_EXPAND_FOOTER_FULL_SCREEN",
    // trade tables
    SET_IS_OPEN_TRADES_OPEN = "ui/SET_IS_OPEN_TRADES_OPEN",
    SET_IS_PENDING_ORDERS_OPEN = "ui/SET_IS_PENDING_ORDERS_OPEN",
    SET_IS_TRADING_HISTORY_OPEN = "ui/SET_IS_TRADING_HISTORY_OPEN",
    // order modify popup
    SET_ORDER_MODIFY_POPUP_OPEN = "ui/SET_ORDER_MODIFY_POPUP_OPEN",
    SET_ORDER_MODIFY_POPUP_REQUEST_PENDING = "ui/SET_ORDER_MODIFY_POPUP_REQUEST_PENDING",

    SET_ALERT_MESSAGE_FAVORITE_ADDED = "ui/SET_ALERT_MESSAGE_FAVORITE_ADDED",
    SET_ALERT_MESSAGE_FAVORITE_REMOVED = "ui/SET_ALERT_MESSAGE_FAVORITE_REMOVED",

    // mobile trade footer actions
    TOGGLE_MOBILE_ACCOUNT_INFO = "ui/TOGGLE_MOBILE_ACCOUNT_INFO",
    TOGGLE_MOBILE_PENDING_ORDERS = "ui/TOGGLE_MOBILE_PENDING_ORDERS",

    SET_ANIMATION = "ui/SET_ANIMATION",
    SET_CHART_TAB = "ui/SET_CHART_TAB",

    LOCK_EXPAND_BUTTON = "ui/LOCK_EXPAND_BUTTON",

    NOTIGICATIONS_TOGGLE = "ui/NOTIGICATIONS_TOGGLE",

    SCROLL_TO_COORDINATES = "ui/SCROLL_TO_COORDINATES",

    TRADE_ALERTS_LOADING = "ui/TRADE_ALERTS_LOADING",
}

export interface ISetTradeOrderViewAction {
    type: typeof UIActionTypes.SET_TRADE_ORDER_VIEW;
    isOpen: boolean;
}

export interface ISetIsChartReadyAction {
    type: typeof UIActionTypes.SET_IS_CHART_READY;
    isReady: boolean;
}

export interface ISetIsFirstRenderAnimationFinishedAction {
    type: typeof UIActionTypes.SET_IS_FIRST_RENDER_ANIMATION_FINISHED;
    isReady: boolean;
}

export interface ISetIsAssetsTableExpandedAction {
    type: typeof UIActionTypes.SET_IS_ASSETS_TABLE_EXPANDED;
    isExpanded: boolean;
}

export interface IOpenTradeOrderModalAction {
    type: typeof UIActionTypes.OPEN_TRADE_ORDER_MODAL;
    modalType: ITradeOrderModalTypes;
    // TODO: modal param types
    params?: TradeOrderModalParamTypes;
}

export interface ICloseTradeOrderModalAction {
    type: typeof UIActionTypes.CLOSE_TRADE_ORDER_MODAL;
    params?: TradeOrderModalParamTypes;
}

export interface ISetTradeOrderViewAction {
    type: typeof UIActionTypes.SET_TRADE_ORDER_VIEW;
    isOpen: boolean;
}

export interface ISetAssetsTabActiveAction {
    type: typeof UIActionTypes.MOBILE_SET_IS_ASSETS_TAB_ACTIVE;
}

export interface ISetTradesTabActiveAction {
    type: typeof UIActionTypes.MOBILE_SET_IS_TRADES_TAB_ACTIVE;
}

export interface ISetWalletTabActiveAction {
    type: typeof UIActionTypes.MOBILE_SET_IS_WALLET_TAB_ACTIVE;
}

export interface ISetToggleExpandFooterAction {
    type: typeof UIActionTypes.TOGGLE_EXPAND_FOOTER;
    value: boolean;
}

export interface ISetToggleExpandFooterFullScreenAction {
    type: typeof UIActionTypes.TOGGLE_EXPAND_FOOTER_FULL_SCREEN;
    isFullscreen: boolean;
}

export interface ISetIsOpenTradesOpenAction {
    type: typeof UIActionTypes.SET_IS_OPEN_TRADES_OPEN;
    isOpen: boolean;
}

export interface ISetIsPendingOrdersOpenAction {
    type: typeof UIActionTypes.SET_IS_PENDING_ORDERS_OPEN;
    isOpen: boolean;
}

export interface ISetToggleMobileFooterAction {
    type: typeof UIActionTypes.TOGGLE_MOBILE_FOOTER;
    value: boolean;
}

export interface ISetToggleMobileHeaderAction {
    type: typeof UIActionTypes.TOGGLE_MOBILE_HEADER;
    value: boolean;
}

export interface ISetOrderModifyPopupOpen {
    type: typeof UIActionTypes.SET_ORDER_MODIFY_POPUP_OPEN;
    isOpen: boolean;
}

export interface ISetOrderModifyPopupRequestPending {
    type: typeof UIActionTypes.SET_ORDER_MODIFY_POPUP_REQUEST_PENDING;
    isPending: boolean;
}

export interface ISetIsTradingHistoryOpenAction {
    type: typeof UIActionTypes.SET_IS_TRADING_HISTORY_OPEN;
    isOpen: boolean;
}

export interface IOpenSingleAssetView {
    type: typeof UIActionTypes.MOBILE_SET_IS_SINGLE_ASSET_VIEW_ACTIVE;
    isOpen: boolean;
}

export interface IMessageFavoriteAddedAction {
    type: typeof UIActionTypes.SET_ALERT_MESSAGE_FAVORITE_ADDED;
    value: string;
}

export interface IMessageFavoriteRemovedAction {
    type: typeof UIActionTypes.SET_ALERT_MESSAGE_FAVORITE_REMOVED;
    value: string;
}

export interface IToggleMobileAccountInfo {
    type: typeof UIActionTypes.TOGGLE_MOBILE_ACCOUNT_INFO;
    isOpen: boolean;
}

export interface IToggleMobilePendingOrders {
    type: typeof UIActionTypes.TOGGLE_MOBILE_PENDING_ORDERS;
    isOpen: boolean;
}

export interface ISetAnimationMobileType {
    type: typeof UIActionTypes.SET_ANIMATION;
    params?: ISetAnimationParams;
}

export interface ISetChartTab {
    type: typeof UIActionTypes.SET_CHART_TAB;
    value: TradeTabs;
}

export interface ILockExpandButtonAction {
    type: typeof UIActionTypes.LOCK_EXPAND_BUTTON;
    value: boolean;
}

export interface IToggleNotificationsPopup {
    type: typeof UIActionTypes.NOTIGICATIONS_TOGGLE;
    value: boolean;
}

export interface IScrollToCoordinatesAction {
    type: typeof UIActionTypes.SCROLL_TO_COORDINATES;
    asset: string;
}

export interface IIsTradeAlertsLoading {
    type: typeof UIActionTypes.TRADE_ALERTS_LOADING;
    isLoading: boolean;
}

export type UIActions =
    | ISetTradeOrderViewAction
    | ISetIsChartReadyAction
    | ISetIsFirstRenderAnimationFinishedAction
    | ISetIsAssetsTableExpandedAction
    | IOpenTradeOrderModalAction
    | ICloseTradeOrderModalAction
    | ISetAssetsTabActiveAction
    | ISetTradesTabActiveAction
    | ISetWalletTabActiveAction
    | ISetToggleExpandFooterAction
    | ISetToggleExpandFooterFullScreenAction
    | ISetIsOpenTradesOpenAction
    | ISetIsPendingOrdersOpenAction
    | ISetToggleMobileFooterAction
    | ISetToggleMobileHeaderAction
    | ISetOrderModifyPopupOpen
    | ISetOrderModifyPopupRequestPending
    | ISetIsTradingHistoryOpenAction
    | IOpenSingleAssetView
    | IMessageFavoriteAddedAction
    | IMessageFavoriteRemovedAction
    | IToggleMobileAccountInfo
    | IToggleMobilePendingOrders
    | ISetAnimationMobileType
    | ISetChartTab
    | ILockExpandButtonAction
    | IToggleNotificationsPopup
    | IScrollToCoordinatesAction
    | IIsTradeAlertsLoading;

export const uiReducer = (state = initialState, action: UIActions): IUIState => {
    const { type } = action;

    switch (type) {
        case UIActionTypes.SET_TRADE_ORDER_VIEW: {
            const { isOpen } = action as ISetTradeOrderViewAction;

            return {
                ...state,
                isTradeOrderViewOpen: isOpen,
            };
        }

        case UIActionTypes.SET_IS_CHART_READY: {
            const { isReady } = action as ISetIsChartReadyAction;

            return {
                ...state,
                isChartReady: isReady,
            };
        }

        case UIActionTypes.SET_IS_FIRST_RENDER_ANIMATION_FINISHED: {
            const { isReady } = action as ISetIsFirstRenderAnimationFinishedAction;

            return {
                ...state,
                isFirstRenderAnimationFinished: isReady,
            };
        }

        case UIActionTypes.SET_IS_ASSETS_TABLE_EXPANDED: {
            const { isExpanded } = action as ISetIsAssetsTableExpandedAction;

            return {
                ...state,
                isAssetsTableExpanded: isExpanded,
            };
        }

        case UIActionTypes.OPEN_TRADE_ORDER_MODAL: {
            const { modalType, params } = action as IOpenTradeOrderModalAction;

            return {
                ...state,
                tradeOrderModal: modalType,
                tradeOrderModalParams: params,
                isTradeOrderViewOpen: true,
            };
        }

        case UIActionTypes.CLOSE_TRADE_ORDER_MODAL: {
            const { params } = action as ICloseTradeOrderModalAction;

            return {
                ...state,
                tradeOrderModal: null,
                tradeOrderModalParams: params,
                isMobilePendingOrdersOpen: false,
            };
        }

        case UIActionTypes.MOBILE_SET_IS_ASSETS_TAB_ACTIVE: {
            return {
                ...state,
                isAssetsTabActive: true,
                isTradesTabActive: false,
                isWalletTabActive: false,
                isSingleAssetViewActive: false,
                isMobilePendingOrdersOpen: false,
            };
        }

        case UIActionTypes.MOBILE_SET_IS_TRADES_TAB_ACTIVE: {
            return {
                ...state,
                isAssetsTabActive: false,
                isTradesTabActive: true,
                isWalletTabActive: false,
                isSingleAssetViewActive: false,
            };
        }

        case UIActionTypes.MOBILE_SET_IS_WALLET_TAB_ACTIVE: {
            return {
                ...state,
                isAssetsTabActive: false,
                isTradesTabActive: false,
                isSingleAssetViewActive: false,
                isWalletTabActive: true,
            };
        }

        case UIActionTypes.TOGGLE_EXPAND_FOOTER: {
            const { value } = action as ISetToggleExpandFooterAction;

            return {
                ...state,
                isFooterExpanded: value,
                isOpenTradesOpen: value ? state.isOpenTradesOpen : false,
                isPendingOrdersOpen: value ? state.isPendingOrdersOpen : false,
                isTradingHistoryOpen: value ? state.isTradingHistoryOpen : false,
            };
        }

        case UIActionTypes.TOGGLE_EXPAND_FOOTER_FULL_SCREEN: {
            const { isFullscreen } = action as ISetToggleExpandFooterFullScreenAction;

            return {
                ...state,
                isFooterExpandedFullScreen: isFullscreen,
            };
        }

        case UIActionTypes.SET_IS_OPEN_TRADES_OPEN: {
            const { isOpen } = action as ISetIsOpenTradesOpenAction;
            return {
                ...state,
                isOpenTradesOpen: isOpen,
                isFooterExpanded: isOpen,
                isPendingOrdersOpen: false,
                isTradingHistoryOpen: false,
                isSingleAssetViewActive: false,
                isFooterExpandedFullScreen: isOpen ? state.isFooterExpandedFullScreen : false,
            };
        }

        case UIActionTypes.SET_IS_PENDING_ORDERS_OPEN: {
            const { isOpen } = action as ISetIsPendingOrdersOpenAction;

            return {
                ...state,
                isPendingOrdersOpen: isOpen,
                isFooterExpanded: isOpen,
                isOpenTradesOpen: false,
                isTradingHistoryOpen: false,
                isSingleAssetViewActive: false,
                isFooterExpandedFullScreen: isOpen ? state.isFooterExpandedFullScreen : false,
            };
        }

        case UIActionTypes.SET_IS_TRADING_HISTORY_OPEN: {
            const { isOpen } = action as ISetIsTradingHistoryOpenAction;

            return {
                ...state,
                isTradingHistoryOpen: isOpen,
                isOpenTradesOpen: false,
                isPendingOrdersOpen: false,
                isFooterExpanded: false,
                isFooterExpandedFullScreen: false,
                isSingleAssetViewActive: false,
            };
        }

        case UIActionTypes.SET_ORDER_MODIFY_POPUP_OPEN: {
            const { isOpen } = action as ISetOrderModifyPopupOpen;

            return {
                ...state,
                isOrderModifyPopupOpen: isOpen,
            };
        }

        case UIActionTypes.SET_ORDER_MODIFY_POPUP_REQUEST_PENDING: {
            const { isPending } = action as ISetOrderModifyPopupRequestPending;

            return {
                ...state,
                isOrderModifyPopupRequestPending: isPending,
            };
        }

        case UIActionTypes.TOGGLE_MOBILE_FOOTER: {
            const { value } = action as ISetToggleMobileFooterAction;

            return {
                ...state,
                showMobileFooter: value,
            };
        }

        case UIActionTypes.TOGGLE_MOBILE_HEADER: {
            const { value } = action as ISetToggleMobileHeaderAction;

            return {
                ...state,
                showMobileHeader: value,
            };
        }

        case UIActionTypes.MOBILE_SET_IS_SINGLE_ASSET_VIEW_ACTIVE: {
            const { isOpen } = action as IOpenSingleAssetView;

            return {
                ...state,
                isSingleAssetViewActive: isOpen,
                isOpenTradesOpen: false,
                isPendingOrdersOpen: false,
                isFooterExpanded: false,
                isFooterExpandedFullScreen: false,
                showMobileFooter: false,
            };
        }

        case UIActionTypes.SET_ALERT_MESSAGE_FAVORITE_ADDED: {
            const { value } = action as IMessageFavoriteAddedAction;
            return {
                ...state,
                alertMessageFavoriteAdded: value ? `${value} ` : "",
            };
        }

        case UIActionTypes.SET_ALERT_MESSAGE_FAVORITE_REMOVED: {
            const { value } = action as IMessageFavoriteRemovedAction;

            return {
                ...state,
                alertMessageFavoriteRemoved: value ? `${value} ` : "",
            };
        }

        case UIActionTypes.TOGGLE_MOBILE_ACCOUNT_INFO: {
            const { isOpen } = action as IToggleMobileAccountInfo;

            return {
                ...state,
                isMobileAccountInfoOpen: isOpen,
                isMobilePendingOrdersOpen: false,
            };
        }

        case UIActionTypes.TOGGLE_MOBILE_PENDING_ORDERS: {
            const { isOpen } = action as IToggleMobilePendingOrders;

            return {
                ...state,
                isMobileAccountInfoOpen: false,
                isMobilePendingOrdersOpen: isOpen,
            };
        }

        case UIActionTypes.SET_ANIMATION: {
            const { params } = action as ISetAnimationMobileType;

            return {
                ...state,
                animationParams: params || initialState.animationParams,
            };
        }

        case UIActionTypes.SET_CHART_TAB: {
            const { value } = action as ISetChartTab;

            return {
                ...state,
                navTab: value,
            };
        }

        case UIActionTypes.LOCK_EXPAND_BUTTON: {
            const { value } = action as ILockExpandButtonAction;

            return {
                ...state,
                isExpandButtonLocked: value,
            };
        }

        case UIActionTypes.NOTIGICATIONS_TOGGLE: {
            const { value } = action as IToggleNotificationsPopup;

            return {
                ...state,
                isNotificationsPopupVisible: value,
            };
        }

        case UIActionTypes.SCROLL_TO_COORDINATES: {
            const { asset } = action as IScrollToCoordinatesAction;

            return {
                ...state,
                assetToScrollOn: asset,
            };
        }
        case UIActionTypes.TRADE_ALERTS_LOADING: {
            const { isLoading } = action as IIsTradeAlertsLoading;

            return {
                ...state,
                isTradeAlertsLoading: isLoading,
            };
        }
    }

    return state;
};
