import { useCallback, useEffect, useState } from "react";
import io from "socket.io-client";
import getConfig from "next/config";
import { useDispatch, Dispatch } from "react-redux";
import Cookies from "js-cookie";
import { processedQuedOrdersString } from "@/utils/helpers";
import { getAccessToken } from "@/services/trade/account";
import { randomNumberInRange } from "@/utils/index";
import { IOrderSocketResponse, orderChangedViaSocket } from "@/redux/actions/orders";
import serverTime from "@/services/serverTime";
import { MARGIN_CALL_COOKIE_KEY } from "@/constants/index";
import { closeTradeOrderModal, openTradeOrderModal } from "@/redux/actions/ui";
import { ITradeOrderModalTypes } from "@/redux/interfaces/IUI";
import { TradingPlatformServerType } from "@/redux/interfaces/IAccount";
import { setStaticAccountInfo } from "@/redux/actions/account";
import { getDomain } from "@/hooks/trade/utils";
import { setAccountDataLoaded } from "@/redux/actions/tradeInfo";
import { ITickOptions } from "@/utils/trade";

export enum PriceChangeType {
    Up = "Up",
    Down = "Down",
    None = "None",
}

export interface IQuoteInfo {
    lastAsk: number;
    lastBid: number;
    ask: number;
    bid: number;
    high: number;
    low: number;
    changeType: PriceChangeType;
    askChangeType: PriceChangeType;
    bidChangeType: PriceChangeType;
    lastTime: number;
}

interface ISocketConnectProps {
    quotesInfo: {
        id: string;
        ask: number;
        bid: number;
        high: number;
        low: number;
    }[];
    onSuccess: () => void;
    dispatch: Dispatch;
}

interface IQuoteSubscribersInfo {
    callbacks: ((q: IQuoteInfo) => void)[];
    updateHandle: any;
}

export interface IStaticAccountInfoResponse {
    account: {
        currency: string;
    };
    accountBalance: number;
    accountCredit: number;
    accountLeverage: number;
    accountMargin: number;
    id: string;
    name: string;
    timeZone: number;
    marginLevelType: "Ok" | "MarginCall" | "StopOut";
    tradingPlatformServerType: TradingPlatformServerType;
}

export interface IDynamicAccountInfoResponse {
    accountEquity: number;
    accountFreeMargin: number;
    accountProfit: number;
}

const QuoteSocketManager = () => {
    let socket = null;
    let dispatch = null;

    const quoteSubscribers: Record<string, IQuoteSubscribersInfo> = {};

    let quotes: Record<string, IQuoteInfo> = {};

    const subscribeToQuote = (options: ITickOptions) => {
        socket.emit("symbol", JSON.stringify(options));
    };

    const handleSymbolQuoteMessage = (data: string) => {
        const updates: { [key: string]: string[] } = JSON.parse(data);
        //console.log("quotes !!! ======>", quotes);
        Object.keys(updates).forEach(key => {
            handleSymbolUpdate({
                ask: Number(updates[key][1]),
                bid: Number(updates[key][2]),
                symbol: updates[key][0],
                lastTime: new Date(),
                ...(updates[key][3] && { lastHigh: Number(updates[key][3]) }),
                ...(updates[key][4] && { lastLow: Number(updates[key][4]) }),
                ...(updates[key][5] && { direction: Number(updates[key][5]) }),
            });
        });
    };

    const handleSymbolUpdate = data => {
        const response = typeof data === "string" ? JSON.parse(data) : data;
        const { ask, bid, symbol: symbolId, lastTime, lastHigh, lastLow, direction } = response;

        if (symbolId && !quotes[symbolId]) {
            quotes[symbolId] = response;
        }
        if (quotes[symbolId]?.lastTime > lastTime) {
            return;
        }

        const askCurrent = quotes[symbolId]?.ask;
        const bidCurrent = quotes[symbolId]?.bid;
        const highCurrent = quotes[symbolId]?.high;
        const lowCurrent = quotes[symbolId]?.low;

        const priceCurrent = askCurrent && bidCurrent ? (askCurrent + bidCurrent) / 2 : null;
        const price = (ask + bid) / 2;

        let high = !highCurrent || bid > highCurrent ? bid : highCurrent;

        if (lastHigh > 0 || !isNaN(lastHigh)) {
            high = lastHigh;
        }

        let low = !lowCurrent || bid < lowCurrent ? bid : lowCurrent;

        if (lastLow > 0 || !isNaN(lastLow)) {
            low = lastLow;
        }

        if (priceCurrent === price) {
            return;
        }

        let priceChangeType = PriceChangeType.None;
        let askChangeType = PriceChangeType.None;
        let bidChangeType = PriceChangeType.None;

        if (priceCurrent && price !== priceCurrent) {
            priceChangeType = price > priceCurrent ? PriceChangeType.Up : PriceChangeType.Down;
        }

        if (askCurrent && ask !== askCurrent) {
            askChangeType = ask > askCurrent ? PriceChangeType.Up : PriceChangeType.Down;
        }

        if (bidCurrent && bid !== bidCurrent) {
            bidChangeType = bid > bidCurrent ? PriceChangeType.Up : PriceChangeType.Down;
        }

        const lastTick = getSymbolQuoteValue(symbolId);

        quotes[symbolId] = {
            lastAsk: lastTick.ask,
            lastBid: lastTick.bid,
            ask,
            bid,
            high,
            low,
            changeType: priceChangeType,
            askChangeType,
            bidChangeType,
            lastTime,
        };

        triggerUpdate(symbolId);
    };

    const triggerUpdate = (symbolId: string) => {
        const subscribers = quoteSubscribers[symbolId];

        if (subscribers?.callbacks?.length) {
            clearTimeout(subscribers.updateHandle);

            subscribers.updateHandle = setTimeout(() => {
                subscribers.callbacks.forEach(cb => cb(quotes[symbolId]));
            }, randomNumberInRange(0, 300));
        }
    };

    const updateHigLowFromChartHistory = (symbolId: string, high: number, low: number) => {
        const symbolQuoteInfo = quotes[symbolId];

        if (
            high &&
            !isNaN(high) &&
            (high > symbolQuoteInfo?.high || !symbolQuoteInfo?.high) &&
            low &&
            !isNaN(low) &&
            (low < symbolQuoteInfo?.low || !symbolQuoteInfo?.low)
        ) {
            symbolQuoteInfo.high = high;
            symbolQuoteInfo.low = low;

            triggerUpdate(symbolId);
        }
    };

    const connect = async ({ quotesInfo, onSuccess, dispatch }: ISocketConnectProps) => {
        if (socket) {
            return;
        } // TODO: add some reconnect

        // build quotes data
        quotes = quotesInfo.reduce((all, symbolQuoteInfo) => {
            const { id: symbolId, ask, bid, high, low } = symbolQuoteInfo;

            return {
                ...all,
                [symbolId]: {
                    bid,
                    ask,
                    high,
                    low,
                    changeType: PriceChangeType.None,
                    askChangeType: PriceChangeType.None,
                    bidChangeType: PriceChangeType.None,
                    lastTime: null,
                },
            };
        }, {});
        onSuccess();

        const { publicRuntimeConfig } = getConfig();
        const urlParams = new URLSearchParams(window.location.search);
        const env = publicRuntimeConfig["envMode"];
        const serverMode: "production" | "stage" | "local" = urlParams.get("server-mode") as any;
        const v5 = urlParams.get("v5") === "1";
        const mtr = urlParams.get("mtr") === "1";

        let socketUrl;

        const host = getDomain(window.location.hostname);

        const localURL = "ws://localhost:3001";
        let stageURL = "wss://trader-ws-stage.adesk-service.com";
        let prodURL = `wss://webtrader-ws.${host}`;
        //MOCKEDURLS

        if (v5) {
            stageURL = "wss://webtrader-ws.stage.v5.adesk-service.com";
            prodURL = `wss://webtrader-ws.v5.${host}`;
        }
        if (mtr) {
            stageURL = `wss://stage-mtrwebtrader-ws.${host}`;
            prodURL = `wss://mtrwebtrader-ws.${host}`;
        }

        /**
         * Getting env variable from build level
         */
        switch (env) {
            case "production":
                socketUrl = prodURL;
                break;
            case "stage":
                socketUrl = stageURL;
                break;
            default:
                socketUrl = prodURL;
                break;
        }

        /**
         * Used for production testing via stage build
         */
        switch (serverMode) {
            case "local":
                socketUrl = localURL;
                break;

            case "production":
                socketUrl = prodURL;
                break;

            case "stage":
                socketUrl = stageURL;
                break;
        }

        socket = io(socketUrl, {
            auth: { token: getAccessToken() },
            transports: ["websocket"],
        });

        socket.on("connect", () => {
            socket.emit("symbols"); // this will trigger quotes.
            socket.emit("balance"); // this will listen to margin calls
            socket.emit("open_trades"); // this will listen to orders
            socket.emit("user_group_change");
        });

        socket.on("quotes", handleSymbolQuoteMessage);
        // REQUEST_PLACED
        const isPlacedOrder = () => {
            const isInQue = !!processedQuedOrdersString.getLast();

            if (isInQue) {
                processedQuedOrdersString.deleteFirst();
                return true;
            }
            return false;
        };

        socket.on("orders", rawData => {
            const result = JSON.parse(rawData) as IOrderSocketResponse;

            const { type, order } = result;

            dispatch(orderChangedViaSocket(result));

            type === "Add" &&
                isPlacedOrder() &&
                dispatch(
                    openTradeOrderModal(ITradeOrderModalTypes.OPEN_ORDER_SUCCESS, {
                        order: order || {},
                    })
                );

            type === "Delete" &&
                isPlacedOrder() &&
                dispatch(
                    openTradeOrderModal(ITradeOrderModalTypes.CLOSE_TRADE_SUCCESS, {
                        order: order || {},
                    })
                );
        });

        socket.on("balance", rawData => {
            const data = JSON.parse(rawData);
            const { timeZone, marginLevelType } = data;

            if (typeof timeZone === "number") {
                serverTime.updateTimezoneInfo(timeZone);
            }

            if (marginLevelType === "MarginCall" && !Cookies.get(MARGIN_CALL_COOKIE_KEY)) {
                dispatch(openTradeOrderModal(ITradeOrderModalTypes.MARGIN_CALL));

                Cookies.set(MARGIN_CALL_COOKIE_KEY, "true", {
                    expires: 1 / 24,
                });
            }
            const accountInfoResponse: IStaticAccountInfoResponse = {
                account: {
                    currency: data.currency,
                },
                accountBalance: data.balance,
                accountCredit: data.credit,
                // accountEquity: data.equity,
                accountLeverage: data.leverage,
                accountMargin: data.usedMargin,
                // accountFreeMargin: data.freeMargin,
                // accountProfit: data.profit,
                id: data.id,
                name: data.name,
                timeZone: data.timeZone,
                marginLevelType: data.marginLevelType,
                tradingPlatformServerType: data.serverType,
            };

            dispatch(setAccountDataLoaded());

            dispatch(setStaticAccountInfo(accountInfoResponse));
        });

        socket.on("user_group_change", () => window.location.reload());

        socket.on("exception", data => {
            console.debug("[DEBUG]: Socket Exception", data);
        });

        socket.on("disconnect", () => {
            console.debug("[DEBUG]: Socket Disconnected");
        });

        const symbols = quotesInfo.map(s => s.id);

        // update subscribers with initial data, if any
        for (const s in symbols) {
            const subscribers = quoteSubscribers[s];

            if (subscribers?.callbacks?.length) {
                subscribers.callbacks.forEach(cb => cb(quotes[s]));
            }
        }
    };

    const subscribe = (_dispatch, symbolId, cb) => {
        dispatch = _dispatch;

        if (!quoteSubscribers[symbolId]) {
            quoteSubscribers[symbolId] = {
                callbacks: [],
                updateHandle: null,
            };
        }

        quoteSubscribers[symbolId].callbacks.push(cb);
    };

    const unsubscribe = (symbolId, cb) => {
        if (!quoteSubscribers[symbolId]) {
            return;
        }

        if (quoteSubscribers[symbolId].callbacks.includes(cb)) {
            quoteSubscribers[symbolId].callbacks.splice(
                quoteSubscribers[symbolId].callbacks.indexOf(cb),
                1
            );
        }
    };

    const getValue = (symbolId): IQuoteInfo => {
        return (
            quotes[symbolId] || {
                lastAsk: null,
                lastBid: null,
                ask: null,
                bid: null,
                high: null,
                low: null,
                changeType: PriceChangeType.None,
                askChangeType: PriceChangeType.None,
                bidChangeType: PriceChangeType.None,
                lastTime: null,
            }
        );
    };

    return {
        connect,
        subscribe,
        subscribeToQuote,
        unsubscribe,
        getValue,
        updateHigLowFromChartHistory,
    };
};

export const quoteSocketManager = QuoteSocketManager();

export const getSymbolQuoteValue = (symbolId: string): IQuoteInfo => {
    return quoteSocketManager.getValue(symbolId);
};

// can be used to trigger updates for aggregated values that use multiple quotes
export const useMultipleQuoteDataSubscribe = (symbolIds: string[], cb: () => void): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        cb();

        symbolIds.forEach(symbolId => quoteSocketManager.subscribe(dispatch, symbolId, () => cb()));

        return () => symbolIds.forEach(symbolId => quoteSocketManager.unsubscribe(dispatch, symbolId));
    }, [symbolIds, cb, dispatch]);

    return null;
};

// Hook and component for extracting socket Quote data
export const useQuoteData = (symbolId?: string, priceChangeTTL = 0): any => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(quoteSocketManager.getValue(symbolId));

    useEffect(() => {
        if (symbolId) {
            setValue(quoteSocketManager.getValue(symbolId));

            const onUpdate = ({
                lastAsk,
                lastBid,
                ask,
                bid,
                high,
                low,
                changeType,
                askChangeType,
                bidChangeType,
                lastTime,
            }: IQuoteInfo) => {
                setValue({
                    lastAsk: lastAsk,
                    lastBid: lastBid,
                    ask,
                    bid,
                    high,
                    low,
                    changeType,
                    askChangeType,
                    bidChangeType,
                    lastTime,
                });
            };

            quoteSocketManager.subscribe(dispatch, symbolId, onUpdate);

            return () => quoteSocketManager.unsubscribe(symbolId, onUpdate);
        }
    }, [symbolId]);

    const resetChange = useCallback(() => {
        setValue({
            ...value,
            changeType: PriceChangeType.None,
            askChangeType: PriceChangeType.None,
            bidChangeType: PriceChangeType.None,
        });
    }, [value]);

    useEffect(() => {
        if (!priceChangeTTL || value.changeType === PriceChangeType.None) {
            return;
        }

        const handle = setTimeout(resetChange, priceChangeTTL);

        return () => clearTimeout(handle);
    }, [priceChangeTTL, resetChange, value.changeType]);

    return value;
};

interface IQuoteDataProps {
    symbolId: string;
    render: (info: IQuoteInfo) => JSX.Element;
    priceChangeTTL?: number; // when present, will automatically reset price change to None after given delay in ms
}

export const QuoteData = (props: IQuoteDataProps): JSX.Element => {
    const { symbolId, render, priceChangeTTL = 0 } = props;
    const value = useQuoteData(symbolId, priceChangeTTL);

    return render(value);
};
