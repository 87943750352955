import { ISetAnimationParams } from "@/hooks/common/useAnimation";
import { ITradeOrder } from "@/services/trade/order";

export enum ITradeOrderModalTypes {
    OPEN_ORDER_SUCCESS = "tradeOrderModal/OPEN_ORDER_SUCCESS",
    ORDER_ERROR = "tradeOrderModal/ORDER_ERROR",
    INSUFFICIENT_FUNDS = "tradeOrderModal/INSUFFICIENT_FUNDS",
    ORDER_DELETE_CONFIRM = "tradeOrderModal/ORDER_DELETE_CONFIRM",
    ORDER_DELETE_SUCCESS = "tradeOrderModal/ORDER_DELETE_SUCCESS",
    CLOSE_TRADE = "tradeOrderModal/CLOSE_TRADE",
    CLOSE_TRADE_SUCCESS = "tradeOrderModal/CLOSE_TRADE_SUCCESS",
    ORDER_MODIFIED_SUCCESS = "ORDER_MODIFIED_SUCCESS",
    MARKET_CLOSED = "tradeOrderModal/MARKET_CLOSED",
    TRADE_DISABLED = "tradeOrderModal/TRADE_DISABLED",

    TRADE_DISABLED_USER_READ_ONLY = "tradeOrderModal/TRADE_DISABLED_USER_READ_ONLY",
    TRADE_DISABLED_ASSET_CLOSE_ONLY = "tradeOrderModal/TRADE_DISABLED_ASSET_CLOSE_ONLY",
    TRADE_DISABLED_EXPIRED_CONTRACT = "tradeOrderModal/TRADE_DISABLED_EXPIRED_CONTRACT",

    INVALID_SL_TP = "tradeOrderModal/INVALID_SL_TP",
    INVALID_PARAMS = "tradeOrderModal/INVALID_PARAMS",
    INVALID_PARAMETERS_WRONG_EXPIRE_DATE = "tradeOrderModal/INVALID_PARAMETERS_WRONG_EXPIRE_DATE",
    OPEN_POSITION = "tradeOrderModal/OPEN_POSITION",
    MARGIN_CALL = "tradeOrderModal/MARGIN_CALL",
    NO_CONNECT = "tradeOrderModal/NO_CONNECT",
    NO_QUOTES = "tradeOrderModal/NO_QUOTES",
    NO_QUOTES_LOW_LIQUIDITY = "tradeOrderModal/NO_QUOTES_LOW_LIQUIDITY",
    ORDER_WITH_WRONG_PRICES = "tradeOrderModal/ORDER_WITH_WRONG_PRICES",
    ORDER_FAILED = "tradeOrderModal/ORDER_FAILED",
    REQUEST_PLACED = "tradeOrderModal/REQUEST_PLACED",
}

export enum TradeTabs {
    chart = "chart",
    info = "information",
    analystForecasts = "analystForecasts",
    technicalAnalysis = "technicalAnalysis",
}

export interface IOrderSuccessModalParams {
    order: ITradeOrder;
}

export interface IOrderDeleteConfirmModalParams {
    order: Partial<ITradeOrder>;
}

export interface IOrderCloseConfirmModalParams {
    order: Partial<ITradeOrder>;
}

export interface IOrderErrorModalParams {
    error: string;
    ticket?: number;
}

export type TradeOrderModalParamTypes =
    | IOrderSuccessModalParams
    | IOrderErrorModalParams
    | IOrderDeleteConfirmModalParams;

export interface IUIState {
    isTradeOrderViewOpen: boolean;
    isAssetsTableExpanded: boolean;
    tradeOrderModal: ITradeOrderModalTypes;
    tradeOrderModalParams: TradeOrderModalParamTypes;
    isAssetsTabActive: boolean;
    isTradesTabActive: boolean;
    isWalletTabActive: boolean;
    isFooterExpanded: boolean;
    isFooterExpandedFullScreen: boolean;
    isTradingHistoryOpen: boolean;
    isOpenTradesOpen: boolean;
    isPendingOrdersOpen: boolean;
    isOrderModifyPopupOpen: boolean;
    isOrderModifyPopupRequestPending: boolean;
    showMobileFooter: boolean;
    showMobileHeader: boolean;
    isSingleAssetViewActive: boolean;
    isMobileAccountInfoOpen: boolean;
    isMobilePendingOrdersOpen: boolean;
    isCalculatorViewActive: boolean;
    alertMessageFavoriteAdded: string;
    alertMessageFavoriteRemoved: string;
    navTab: TradeTabs;
    isChartReady: boolean;
    isFirstRenderAnimationFinished: boolean;
    animationParams: ISetAnimationParams;
    isExpandButtonLocked: boolean;
    isNotificationsPopupVisible: boolean;
    assetToScrollOn: string;
    isTradeAlertsLoading: boolean;
}
